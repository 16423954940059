// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-about-js": () => import("./../../../src/pages/company/about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-contact-js": () => import("./../../../src/pages/company/contact.js" /* webpackChunkName: "component---src-pages-company-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-facial-recognition-face-analysis-js": () => import("./../../../src/pages/products/facial-recognition/face-analysis.js" /* webpackChunkName: "component---src-pages-products-facial-recognition-face-analysis-js" */),
  "component---src-pages-products-facial-recognition-face-grouping-js": () => import("./../../../src/pages/products/facial-recognition/face-grouping.js" /* webpackChunkName: "component---src-pages-products-facial-recognition-face-grouping-js" */),
  "component---src-pages-products-facial-recognition-face-search-js": () => import("./../../../src/pages/products/facial-recognition/face-search.js" /* webpackChunkName: "component---src-pages-products-facial-recognition-face-search-js" */),
  "component---src-pages-products-facial-recognition-face-verification-js": () => import("./../../../src/pages/products/facial-recognition/face-verification.js" /* webpackChunkName: "component---src-pages-products-facial-recognition-face-verification-js" */),
  "component---src-pages-products-facial-recognition-index-js": () => import("./../../../src/pages/products/facial-recognition/index.js" /* webpackChunkName: "component---src-pages-products-facial-recognition-index-js" */),
  "component---src-pages-products-facial-recognition-smart-avatar-js": () => import("./../../../src/pages/products/facial-recognition/smart-avatar.js" /* webpackChunkName: "component---src-pages-products-facial-recognition-smart-avatar-js" */),
  "component---src-pages-products-image-recognition-index-js": () => import("./../../../src/pages/products/image-recognition/index.js" /* webpackChunkName: "component---src-pages-products-image-recognition-index-js" */),
  "component---src-pages-products-image-recognition-smart-crop-js": () => import("./../../../src/pages/products/image-recognition/smart-crop.js" /* webpackChunkName: "component---src-pages-products-image-recognition-smart-crop-js" */),
  "component---src-pages-products-image-recognition-tagging-js": () => import("./../../../src/pages/products/image-recognition/tagging.js" /* webpackChunkName: "component---src-pages-products-image-recognition-tagging-js" */),
  "component---src-pages-products-ocr-birth-certificate-js": () => import("./../../../src/pages/products/ocr/birth-certificate.js" /* webpackChunkName: "component---src-pages-products-ocr-birth-certificate-js" */),
  "component---src-pages-products-ocr-business-registration-certificate-js": () => import("./../../../src/pages/products/ocr/business-registration-certificate.js" /* webpackChunkName: "component---src-pages-products-ocr-business-registration-certificate-js" */),
  "component---src-pages-products-ocr-cambodia-id-card-js": () => import("./../../../src/pages/products/ocr/cambodia-id-card.js" /* webpackChunkName: "component---src-pages-products-ocr-cambodia-id-card-js" */),
  "component---src-pages-products-ocr-image-to-text-js": () => import("./../../../src/pages/products/ocr/image-to-text.js" /* webpackChunkName: "component---src-pages-products-ocr-image-to-text-js" */),
  "component---src-pages-products-ocr-index-js": () => import("./../../../src/pages/products/ocr/index.js" /* webpackChunkName: "component---src-pages-products-ocr-index-js" */),
  "component---src-pages-products-ocr-license-plate-js": () => import("./../../../src/pages/products/ocr/license-plate.js" /* webpackChunkName: "component---src-pages-products-ocr-license-plate-js" */),
  "component---src-pages-products-ocr-myanmar-id-card-js": () => import("./../../../src/pages/products/ocr/myanmar-id-card.js" /* webpackChunkName: "component---src-pages-products-ocr-myanmar-id-card-js" */),
  "component---src-pages-products-ocr-passport-js": () => import("./../../../src/pages/products/ocr/passport.js" /* webpackChunkName: "component---src-pages-products-ocr-passport-js" */),
  "component---src-pages-products-ocr-philippines-id-card-js": () => import("./../../../src/pages/products/ocr/philippines-id-card.js" /* webpackChunkName: "component---src-pages-products-ocr-philippines-id-card-js" */),
  "component---src-pages-products-ocr-table-js": () => import("./../../../src/pages/products/ocr/table.js" /* webpackChunkName: "component---src-pages-products-ocr-table-js" */),
  "component---src-pages-products-ocr-vehicle-inspection-certificate-js": () => import("./../../../src/pages/products/ocr/vehicle-inspection-certificate.js" /* webpackChunkName: "component---src-pages-products-ocr-vehicle-inspection-certificate-js" */),
  "component---src-pages-products-ocr-vehicle-registration-js": () => import("./../../../src/pages/products/ocr/vehicle-registration.js" /* webpackChunkName: "component---src-pages-products-ocr-vehicle-registration-js" */),
  "component---src-pages-products-ocr-vietnam-id-cards-js": () => import("./../../../src/pages/products/ocr/vietnam-id-cards.js" /* webpackChunkName: "component---src-pages-products-ocr-vietnam-id-cards-js" */),
  "component---src-pages-solutions-e-kyc-js": () => import("./../../../src/pages/solutions/e-kyc.js" /* webpackChunkName: "component---src-pages-solutions-e-kyc-js" */),
  "component---src-pages-solutions-insurance-js": () => import("./../../../src/pages/solutions/insurance.js" /* webpackChunkName: "component---src-pages-solutions-insurance-js" */),
  "component---src-pages-solutions-media-publishing-js": () => import("./../../../src/pages/solutions/media-publishing.js" /* webpackChunkName: "component---src-pages-solutions-media-publishing-js" */)
}

